<template>
  <div>
    <action-buttons
      :id="formData.id"
      :finished="formData.finished"
    />
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-title>Müşteri Kartı</b-card-title>
          <b-card-text><customer-card /></b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Makine Kartı</b-card-title>
          <b-card-text>
            <machine-card />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Servis Bakım</b-card-title>
          <b-card-sub-title class="mb-1">
            No: {{ formData.service_no }}
          </b-card-sub-title>
          <b-card-text>
            <service-card
              :service-data="formData"
              :works-data="worksData"
            />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Seyahat</b-card-title>
          <b-card-text>
            <travel-card :travel-data="formData" />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BCardText,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import CustomerCard from '@/views/App/Services/Components/CustomerCard'
// eslint-disable-next-line import/extensions
import MachineCard from '@/views/App/Services/Components/MachineCard'
// eslint-disable-next-line import/extensions
import TravelCard from '@/views/App/Services/Components/TravelCard'
// eslint-disable-next-line import/extensions
import ServiceCard from '@/views/App/Services/Components/ServiceCard'
// eslint-disable-next-line import/extensions
import ActionButtons from '@/views/App/Services/Components/ActionButtons'

export default {
  name: 'View',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    CustomerCard,
    MachineCard,
    ServiceCard,
    TravelCard,
    ActionButtons,
  },
  data() {
    return {
      formData: {
        id: null,
        service_no: null,
        related_person: null,
        related_phone: null,
        notes: null,
        finished: null,
        invoiced_status: null,
        work_type: null,
        job: null,
        city_out1: null,
        city_out2: null,
        district_out1: null,
        district_out2: null,
        sdatetime_out: null,
        edatetime_out: null,
        city_return1: null,
        city_return2: null,
        district_return1: null,
        district_return2: null,
        sdatetime_return: null,
        edatetime_return: null,
        id_cities_out1: null,
        id_cities_out2: null,
        id_districts_out1: null,
        id_districts_out2: null,
        id_cities_return1: null,
        id_cities_return2: null,
        id_districts_return1: null,
        id_districts_return2: null,
        id_service_statuses: null,
        id_work_types: null,
        id_jobs: null,
        id_invoiced_statuses: null,
        id_machines: null,
        id_customers: null,
        id_customer_address: null,
      },
      worksData: [],
    }
  },
  computed: {
    service() {
      return this.$store.getters['services/getService']
    },
    serviceWorks() {
      return this.$store.getters['serviceWorks/getService_works']
    },
  },
  watch: {
    service(data) {
      if (data.id) {
        this.setFormData(data)
        this.getCustomer(data.id_customers)
        this.getCustomerAddress(data.id_customer_address)
        this.getMachine(data.id_machines)
      }
    },
    serviceWorks(data) {
      this.setWorksData(data)
    },
  },
  created() {
    this.getService()
    this.getServiceWorks()
  },
  methods: {
    getService() {
      this.$store.dispatch('services/serviceView', this.$route.params.id)
    },
    getServiceWorks() {
      this.$store.dispatch('serviceWorks/service_worksList', {
        where: {
          'service_works.id_services': this.$route.params.id,
        },
      })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCustomerAddress(id) {
      this.$store.dispatch('customerAddress/customerAddressView', id)
    },
    getMachine(id) {
      this.$store.dispatch('machines/machineView', id)
    },
    setFormData(data) {
      this.formData.id = Number(data.id)
      this.formData.service_no = data.service_no
      this.formData.related_person = data.related_person
      this.formData.related_phone = data.related_phone
      this.formData.notes = data.notes
      this.formData.finished = data.finished
      this.formData.invoiced_status = { title: data.invoiced_status }
      this.formData.work_type = { title: data.work_type }
      this.formData.job = { title: data.job }
      this.formData.city_out1 = { title: data.city_out1 }
      this.formData.city_out2 = { title: data.city_out2 }
      this.formData.district_out1 = { title: data.district_out1 }
      this.formData.district_out2 = { title: data.district_out2 }
      this.formData.sdatetime_out = this.moment(data.sdatetime_out).format('DD.MM.YYYY hh:mm')
      this.formData.edatetime_out = this.moment(data.edatetime_out).format('DD.MM.YYYY hh:mm')
      this.formData.city_return1 = { title: data.city_return1 }
      this.formData.city_return2 = { title: data.city_return2 }
      this.formData.district_return1 = { title: data.district_return1 }
      this.formData.district_return2 = { title: data.district_return2 }
      this.formData.sdatetime_return = this.moment(data.sdatetime_return).format('DD.MM.YYYY hh:mm')
      this.formData.edatetime_return = this.moment(data.edatetime_return).format('DD.MM.YYYY hh:mm')
    },
    setWorksData(data) {
      if (data.length > 0) {
        const { worksData } = this
        data.forEach(e => {
          worksData.push({
            sdate: this.moment(e.sdate).format('DD.MM.YYYY HH:mm'),
            edate: this.moment(e.edate).format('DD.MM.YYYY HH:mm'),
          })
        })
        this.worksData = worksData
      }
    },
  },
}
</script>

<style scoped></style>
