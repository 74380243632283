<template>
  <b-row>
    <b-col cols="12">
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col>
              <div class="font-weight-bold text-primary">
                Proje Numarası
              </div>
              {{ machine.project }}
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Fabrika / Seri No
              </div>
              {{ machine.serial }}
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Kurulum Yılı
              </div>
              {{ machine.syear }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col>
              <div class="font-weight-bold text-primary">
                Makine Tipi
              </div>
              {{ machine.machine_type }}
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Makine Modeli
              </div>
              {{ machine.machine_model }}
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Temizleme Teknolojisi
              </div>
              {{ machine.machine_cleaning }}
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Makine Durumu
              </div>
              {{ machine.machine_status }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="machine.notes">
          <div class="font-weight-bold text-primary">
            Notlar
          </div>
          {{ machine.notes }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'MachineCard',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    machine() {
      return this.$store.getters['machines/getMachine']
    },
  },
}
</script>

<style scoped>

</style>
