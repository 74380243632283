<template>
  <b-row>
    <b-col>
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col>
              <div class="font-weight-bold text-primary">
                İlgili Kişi
              </div>
              {{ serviceData.related_person }}
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Telefon
              </div>
              {{ serviceData.related_phone }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col v-if="serviceData.invoiced_status">
              <div class="font-weight-bold text-primary">
                Ücretlendirme
              </div>
              {{ serviceData.invoiced_status.title }}
            </b-col>
            <b-col v-if="serviceData.work_type">
              <div class="font-weight-bold text-primary">
                Çalışma Sınıfı
              </div>
              {{ serviceData.work_type.title }}
            </b-col>
            <b-col v-if="serviceData.job">
              <div class="font-weight-bold text-primary">
                İş
              </div>
              {{ serviceData.job.title }}
            </b-col>
            <b-col v-if="serviceData.finished_status">
              <div class="font-weight-bold text-primary">
                İş Durumu
              </div>
              {{ serviceData.finished_status.title }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="serviceData.notes">
          <div class="font-weight-bold text-primary">
            Yapılan İşler / Kullanılan Malzemeler / Müşteri ile Mutabakat
          </div>
          {{ serviceData.notes }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
      cols="12"
      class="mt-2"
    >
      <b-list-group>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Çalışma Süresi
          </div>
        </b-list-group-item>
        <b-list-group-item
          v-for="(work,key) in worksData"
          :key="key"
        >
          <b-row>
            <b-col>
              <div class="font-weight-bold text-primary">
                Başlangıç
              </div>
              <div>{{ work.sdate }}</div>
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Bitiş
              </div>
              <div>{{ work.edate }}</div>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'ServiceCard',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  props: {
    serviceData: Object,
    worksData: Array,
  },
}
</script>

<style scoped>

</style>
