<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-list-group>
        <b-list-group-item>
          <div class="font-weight-bold text-primary">
            Firma Adı
          </div>
          {{ customer.company }}
        </b-list-group-item>
        <b-list-group-item v-if="customer.phone ||customer.fax || customer.email">
          <b-row>
            <b-col v-if="customer.phone">
              <div class="font-weight-bold text-primary">
                Telefon
              </div>
              {{ customer.phone }}
            </b-col>
            <b-col v-if="customer.fax">
              <div class="font-weight-bold text-primary">
                Fax
              </div>
              {{ customer.fax }}
            </b-col>
            <b-col v-if="customer.email">
              <div class="font-weight-bold text-primary">
                E-Posta
              </div>
              {{ customer.email }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="address">
          <div
            v-if="address.title"
            class="font-weight-bold text-primary"
          >
            {{ address.title }}
          </div>
          <div>{{ address.address }}</div>
          <div>{{ address.city }} / {{ address.district }}</div>
        </b-list-group-item>
        <b-list-group-item v-if="customer.notes">
          <div class="font-weight-bold text-primary">
            Firma Notu
          </div>
          {{ customer.notes }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'CustomerCard',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    address() {
      return this.$store.getters['customerAddress/getCustomerAddress']
    },
  },
}
</script>

<style scoped>

</style>
