import { render, staticRenderFns } from "./ServiceCard.vue?vue&type=template&id=84f2b092&scoped=true&"
import script from "./ServiceCard.vue?vue&type=script&lang=js&"
export * from "./ServiceCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84f2b092",
  null
  
)

export default component.exports