<template>
  <b-row>
    <b-col
      cols="12"
    >
      <div class="font-weight-bold mb-1">
        Gidiş
      </div>
      <b-row>
        <b-col
          xs="12"
          md="6"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Çıkış İl
              </div>
              <span v-if="travelData.city_out1">
                {{ travelData.city_out1.title }}
              </span>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Çıkış İlçe
              </div>
              <span v-if="travelData.district_out1">
                {{ travelData.district_out1.title }}
              </span>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Çıkış Tarih - Saat
              </div>
              {{ travelData.sdatetime_out }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col
          xs="12"
          md="6"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Varış İl
              </div>
              <span v-if="travelData.city_out2">
                {{ travelData.city_out2.title }}
              </span>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Varış İlçe
              </div>
              <span v-if="travelData.district_out2">
                {{ travelData.district_out2.title }}
              </span>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Varış Tarih - Saat
              </div>
              {{ travelData.edatetime_out }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      class="mt-2"
    >
      <div class="font-weight-bold mb-1">
        Dönüş
      </div>
      <b-row>
        <b-col
          xs="12"
          md="6"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Çıkış İl
              </div>
              <span v-if="travelData.city_return1">
                {{ travelData.city_return1.title }}
              </span>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Çıkış İlçe
              </div>
              <span v-if="travelData.district_return1">
                {{ travelData.district_return1.title }}
              </span>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Çıkış Tarih - Saat
              </div>
              {{ travelData.sdatetime_return }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col
          xs="12"
          md="6"
        >
          <b-list-group>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Varış İl
              </div>
              <span v-if="travelData.city_return2">
                {{ travelData.city_return2.title }}
              </span>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Varış İlçe
              </div>
              <span v-if="travelData.district_return2">
                {{ travelData.district_return2.title }}
              </span>
            </b-list-group-item>
            <b-list-group-item>
              <div class="font-weight-bold text-primary">
                Varış Tarih - Saat
              </div>
              {{ travelData.edatetime_return }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'TravelCard',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  props: {
    travelData: Object,
  },
}
</script>

<style scoped>

</style>
